export default [
  {
    name: 'Guardian Sport themed',
    values: {
      primaryThemeColor: '#0084c6'
    }
  },
  {
    name: 'Muted autoplay w/out ads',
    values: {
      autoplay: true,
      disableAds: true,
      mute: true
    }
  },
  {
    name: 'Show Events',
    values: {
      enablejsapi: true
    }
  },
]
