import React from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import YoutubePlayer from '../components/youtube/player';
import YoutubePlayerControls from '../components/youtube/controls';
import About from '../components/about';
import Presets from '../components/presets';
import Toolbar from '../components/toolbar';
import defaultParams from '../params';
import {
  paramsFromLocation,
  paramsFromObject,
  shapeParams,
  paramsToUrlQueryString,
} from '../util/querystring';

const Container = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`;

const Body = styled.div`
  padding: 10px;
`;

const Footer = styled.footer`
  background-color: var(--theme-highlight-background-colour);
  padding: 20px;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
`;

export default class Page extends React.Component {
  state = {
    videoId: 'PKq8MqrvYU8',
    playerParams: defaultParams,
    stagedPlayerParams: defaultParams,
  };

  componentDidMount() {
    // TODO this is probably more correct when done within the Gatsby framework
    const paramsFromUrl = paramsFromLocation(this.props.location);
    this.setState({
      playerParams: paramsFromUrl,
      stagedPlayerParams: paramsFromUrl,
    });
  }

  onChange = (key, value) => {
    const { stagedPlayerParams } = this.state;

    const updated = {
      ...stagedPlayerParams,
      [key]: { ...stagedPlayerParams[key], value },
    };
    this.setState({ stagedPlayerParams: updated });
  };

  onPresetSelect = presetParams => {
    const params = paramsFromObject(presetParams);
    this.setState({
      playerParams: params,
      stagedPlayerParams: params,
    });
  };

  applyChanges = () => {
    const { stagedPlayerParams } = this.state;

    this.setState({
      playerParams: stagedPlayerParams,
    });

    const newQs = paramsToUrlQueryString(stagedPlayerParams);
    navigate(`?${newQs}`, { replace: false });
  };

  resetToDefaults = () => {
    this.setState({
      playerParams: defaultParams,
      stagedPlayerParams: defaultParams,
    });

    navigate(`/`, { replace: false });
  };

  render() {
    const props = this.props;
    const { videoId, playerParams, stagedPlayerParams } = this.state;
    const { enablejsapi } = playerParams;
    const params = shapeParams(playerParams);

    return (
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>YouTube PfP Player Demo</title>
        </Helmet>
        <header>
          <About />
        </header>
        <Body>
          <Presets onPresetClick={this.onPresetSelect} />
          <YoutubePlayer
            videoId={videoId}
            params={params}
            showEvents={enablejsapi.value}
          />
          <YoutubePlayerControls
            params={stagedPlayerParams}
            onChange={this.onChange}
          />
        </Body>
        <Footer>
          <Toolbar
            apply={this.applyChanges}
            reset={this.resetToDefaults}
            {...props}
          />
        </Footer>
      </Container>
    );
  }
}
