import React from 'react';
import styled from 'styled-components';
import { BOOLEAN, CONSTRAINED_LIST, NUMBER, TEXT, TEXT_LIST } from '../../params';

const Form = styled.form`
  margin: 10px 0;
`;

const FormField = styled.div`
  display: block;
  padding-bottom: 10px;
`;

const FieldName = styled.div`
  font-weight: 700;
`;

const Label = styled.label`
  margin-left: 10px;
`;

const Checkbox = ({ name, initialValue, description, onChange }) => (
  <FormField title={description}>
    <FieldName>{name}</FieldName>
    <input type="checkbox"
           id={name}
           checked={initialValue}
           onChange={e => onChange(e.target.id, e.target.checked)}/>
    <Label htmlFor={name}>{description}</Label>
  </FormField>
);

const Text = ({ name, initialValue, description, onChange, delimiter }) => (
  <FormField title={description}>
    <FieldName>{name}</FieldName>
    <input type="text"
           id={name}
           defaultValue={initialValue}
           onChange={e => {
             const { id, value } = e.target;

             return delimiter
               ? onChange(id, value.split(delimiter))
               : onChange(id, value)
           }}/>
    <Label htmlFor={name}>{description}</Label>
  </FormField>
);

const Number = ({ name, initialValue, description, onChange }) => (
  <FormField title={description}>
    <FieldName>{name}</FieldName>
    <input type="number"
           id={name}
           defaultValue={initialValue}
           min={0}
           onChange={e => onChange(e.target.id, e.target.value)}/>
    <Label htmlFor={name}>{description}</Label>
  </FormField>
);

const Select = ({ name, initialValue, description, onChange, options }) => (
  <FormField title={description}>
    <FieldName>{name}</FieldName>
    <select id={name} value={initialValue} onChange={e => onChange(e.target.id, e.target.value)}>
      { options.map(option => <option key={option} value={option}>{option}</option>) }
    </select>
    <Label htmlFor={name}>{description}</Label>
  </FormField>
);

const YoutubePlayerControls = ({ params, onChange }) => {
  return (
    <Form>
      {
        Object.keys(params).map(paramName => {
          const param = params[paramName];
          const { defaultValue, value } = param;
          const initialValue = typeof value !== 'undefined' ? value : defaultValue;

          const props = {name: paramName, initialValue, onChange, ...param};

          switch (param.type) {
            case BOOLEAN: {
              return <Checkbox key={paramName} {...props}/>
            }
            case NUMBER: {
              return <Number key={paramName} {...props}/>
            }
            case TEXT: {
              return <Text key={paramName} {...props}/>
            }
            case CONSTRAINED_LIST: {
              return <Select key={paramName} {...props}/>
            }
            case TEXT_LIST: {
              return <Text key={paramName} {...props} delimiter=","/>
            }
            default: {
              return null;
            }
          }
        })
      }
    </Form>
  );
};

export default YoutubePlayerControls;
