import React from 'react';
import styled from 'styled-components';
import sleep from '../util/sleep';

const Button = styled.button`
  background-color: var(--theme-button-background-colour);
  padding: 10px;
  color: var(--theme-button-text-colour);
  height: 100%;
  border: 0;
  cursor: pointer;
  margin-left: 5px;
  :hover {
    background-color: var(--theme-button-background-colour-hover);
  }

  &[disabled] {
    background-color: var(--colour-scheme-background);
    color: var(--colour-scheme-text-colour);
    :hover: {
      background-color: transparent;
    }
  }
`;

const ApplyButton = styled(Button)`
  background-color: var(--theme-primary-button-background-colour);
  :hover {
    background-color: var(--theme-primary-button-background-colour-hover);
  }
`;

export default class Toolbar extends React.Component {
  state = {
    copied: false
  };

  copyToClipboard = async (text) => {
    navigator.clipboard.writeText(text)
      .then(_ => this.setState({copied: true}))
      .then(_ => sleep(1000))
      .then(_ => this.setState({copied: false}));
  };

  render() {
    const { reset, apply, location: { href } } = this.props;
    const { copied } = this.state;

    return (
      <>
        <Button onClick={() => this.copyToClipboard(href)} disabled={copied}>
          {copied ? 'Copied!' : 'Share'}
        </Button>
        <Button onClick={() => reset()}>Reset to Defaults</Button>
        <ApplyButton onClick={() => apply()}>Apply Changes</ApplyButton>
      </>
    );
  }
}
