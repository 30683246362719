import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import presets from '../presets';

const PresetList = styled.ul`
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 10px 0;
`;

const Preset = styled.li`
  display: inline-block;
  padding: 10px;
  margin: 5px;
  background-color: var(--theme-highlight-background-colour);
  border-radius: 10px;
`;

export default ({ onPresetClick }) => {
  return (
    <PresetList>
      {presets.map((preset, index) => {
        const qs = new URLSearchParams(preset.values);
        return (
          <Preset key={index}>
            <Link to={`/?${qs}`} onClick={() => onPresetClick(preset.values)}>
              {preset.name}
            </Link>
          </Preset>
        );
      })}
    </PresetList>
  );
};
