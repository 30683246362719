import React from 'react';

export default () => {
  return (
    <div>
      <h1>YouTube PfP Player Demo.</h1>
      An adaptation of the <a href="https://developers.google.com/youtube/youtube_player_demo" rel="noopener noreferrer" target="_blank">YouTube Player Demo</a> to include the options available under <a href="https://www.blog.google/outreach-initiatives/google-news-initiative/digital-news-initiative-introducing/" rel="noopener noreferrer" target="_blank">YouTube PfP</a>.
      Simply adjust the parameters and press <code>Apply Changes</code> to see how they effect the iframe player. You can also share your selected configuration using the URL.
    </div>
  );
}
