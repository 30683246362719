import ISO6391 from 'iso-639-1';

export const BOOLEAN = 'boolean';
export const TEXT = 'text';
export const NUMBER = 'number';
export const CONSTRAINED_LIST = 'constrained-list';
export const TEXT_LIST = 'text-list';

// see https://developers.google.com/youtube/player_parameters#Parameters
export const standardPlayerParams = {
  autoplay: {
    type: BOOLEAN,
    description: 'start playback automatically on load',
    defaultValue: false
  },
  cc_lang_pref: {
    type: CONSTRAINED_LIST,
    description: 'default language that the player will use to display captions',
    defaultValue: 'none',
    options: ['none', ...ISO6391.getAllCodes()]
  },
  cc_load_policy: {
    type: BOOLEAN,
    description: 'show/hide closed captions',
    defaultValue: false
  },
  color: {
    type: CONSTRAINED_LIST,
    description: `colour of the progress bar`,
    defaultValue: 'none',
    options: ['none', 'red', 'white']
  },
  controls: {
    type: BOOLEAN,
    description: 'show video controls',
    defaultValue: true
  },
  disablekb: {
    type: BOOLEAN,
    description: 'disable keyboard controls',
    defaultValue: false
  },
  enablejsapi: {
    type: BOOLEAN,
    description: 'allow video to be controlled by the iframe api, enable this to show player events',
    defaultValue: false
  },
  end: {
    type: NUMBER,
    description: 'where to end video at (seconds)',
    defaultValue: undefined
  },
  fs: {
    type: BOOLEAN,
    description: 'show fullscreen button',
    defaultValue: true
  },
  hl: {
    type: CONSTRAINED_LIST,
    description: `the player's interface language`,
    defaultValue: 'none',
    options: ['none', ...ISO6391.getAllCodes()]
  },
  iv_load_policy: {
    type: BOOLEAN,
    description: 'show/hide annotations',
    defaultValue: false
  },
  list: {
    type: TEXT,
    description: 'identifies the content that will load in the player',
    defaultValue: undefined
  },
  listType: {
    type: CONSTRAINED_LIST,
    description: 'identifies the content that will load in the player',
    defaultValue: 'none',
    options: ['none', 'playlist', 'search', 'user_upload']
  },
  loop: {
    type: BOOLEAN,
    description: 'loop video (or playlist)',
    defaultValue: false
  },
  modestbranding: {
    type: BOOLEAN,
    description: 'remove YouTube logo from control bar',
    defaultValue: false
  },
  mute: {
    type: BOOLEAN,
    description: 'start video with muted audio',
    defaultValue: false
  },
  origin: {
    type: TEXT,
    description: 'provides an extra security measure for the IFrame API and is only supported for IFrame embeds',
    defaultValue: undefined
  },
  playlist: {
    type: TEXT,
    description: 'a comma-separated list of video IDs to play',
    defaultValue: undefined
  },
  playsinline: {
    type: BOOLEAN,
    description: 'play video inline on iOS',
    defaultValue: false
  },
  rel: {
    type: BOOLEAN,
    description: 'show related videos at end',
    defaultValue: true
  },
  showinfo: {
    type: BOOLEAN,
    description: 'show video info',
    defaultValue: true
  },
  start: {
    type: NUMBER,
    description: 'where to start video from (seconds)',
    defaultValue: 0
  },
  widget_referrer: {
    type: TEXT,
    description: 'the URL where the player is embedded. Used in YouTube Analytics when player is embedded in a widget',
    defaultValue: undefined
  },
};

// see https://static.googleusercontent.com/media/youtube.com/en//yt/player-for-publishers/pdf/iframe-embed-config-api.pdf
export const embedConfigParams = {
  primaryThemeColor: {
    type: TEXT,
    description: 'colour of the control bar',
    defaultValue: undefined
  },
  autonavRelatedVideos: {
    type: BOOLEAN,
    description: 'automatically play next video once current has ended',
    defaultValue: false
  },
  disableRelatedVideos: {
    type: BOOLEAN,
    description: 'hide related videos at end',
    defaultValue: false
  },
  enableIma: {
    type: BOOLEAN,
    description: 'enable IMA API (disableAds must also be checked)',
    defaultValue: false,
  },
  relatedVideos: {
    type: TEXT_LIST,
    description: 'up to 12 related videos, overriding the machine learning algorithm from YouTube',
    defaultValue: undefined
  },
  relatedChannels: {
    type: TEXT_LIST,
    description: 'scope the related videos which show to only come from certain channels. Note that you need to be content owner for these channels.',
    defaultValue: undefined
  }
};

export const adsConfigParams = {
  disableAds: {
    type: BOOLEAN,
    description: 'disable all ads',
    defaultValue: false
  },
  nonPersonalizedAd: {
    type: BOOLEAN,
    description: `don't show ads personalised to viewer`,
    defaultValue: false
  }
};

export const adTagParametersParams = {
  iu: {
    type: TEXT,
    description: 'Network ID / ad unit name',
    defaultValue: undefined
  },
  cust_params: {
    type: TEXT,
    description: `URL-encoded kay/value pairs of ad targeting custom parameters`,
    defaultValue: undefined
  },
  ppid: {
    type: TEXT,
    description: `Optional publisher-provided ID for frequency capping / audience / identifier features`,
    defaultValue: undefined
  },
  pp: {
    type: TEXT,
    description: `"player profiles", used to limit which ad media files you want`,
    defaultValue: undefined
  }
};

const allParams = {...standardPlayerParams, ...embedConfigParams, ...adsConfigParams, ...adTagParametersParams};

export default Object.keys(allParams)
  .reduce((acc, param) => ({ ...acc, [param]: allParams[param] }), {});
