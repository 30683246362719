function loadScript({ src, ...props }) {
  if (document.querySelector(`script[src="${src}"`)) {
    return Promise.resolve(`script ${src} already loaded`);
  }

  return new Promise((resolve, reject) => {
    const ref = document.scripts[0];
    const script = document.createElement('script');
    script.src = src;
    Object.assign(script, props);
    script.onload = () => resolve(`loaded script ${src}`);
    script.onerror = () => reject(new Error(`failed to load script ${src}`));

    if (ref.parentNode) {
      ref.parentNode.insertBefore(script, ref);
    }
  });
}

let scriptsPromise;
let iframeReadyPromise;

const loadScripts = () => {
  if (scriptsPromise) {
    return scriptsPromise;
  }

  const scripts = [
    loadScript({ src: 'https://www.youtube.com/iframe_api?ima=1' }),
    loadScript({ src: '//imasdk.googleapis.com/js/sdkloader/ima3.js' }),
  ];
  scriptsPromise = Promise.all(scripts);
  return scriptsPromise;
};

const iframeReady = () => {
  if (iframeReadyPromise) {
    return iframeReadyPromise;
  }
  iframeReadyPromise = new Promise(resolve => {
    window.onYouTubeIframeAPIReady = () => {
      resolve(window.YT);
    };
  });
  return iframeReadyPromise;
};

export const youTubeIframeAPIReady = () =>
  loadScripts().then(() => {
    if (window.YT && window.YT.Player && window.YT.Player instanceof Function) {
      return Promise.resolve(window.YT);
    }

    return iframeReady();
  });
