import React from 'react';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import { youTubeIframeAPIReady } from '../../util/loadYoutubeScript';

const Player = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const SixteenByNineContainer = styled.div`
  position: relative;
`;

const Events = styled.ol`
  height: 50px;
  overflow: scroll;
  margin: 0;
  margin-top: 10px;
  padding: 5px;
  background-color: #333;
  border-radius: 10px;
`;

const Event = styled.code`
  display: block;
`;

function makeAdsRequest(adsRequest) {
  // from https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/tags
  adsRequest.adTagUrl =
    'https://pubads.g.doubleclick.net/gampad/ads?' +
    'iu=/21775744923/external/single_ad_samples&sz=640x480&' +
    'cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&' +
    'gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
}

let player;
var playerIFrameID = 'youtube-player';
var adDivID = 'ad-container';

export default class YoutubePlayer extends React.Component {
  state = {
    events: [],
  };

  constructor(props) {
    super(props);

    this.player = null;
    this.setPlayerRef = el => (this.player = el);
  }

  addEvent = event => {
    const { events } = this.state;
    this.setState({
      events: [`${dateFormat(new Date(), 'isoTime')} - ${event}`, ...events],
    });
  };

  initializeYouTubePlayer = (videoId, params) => {
    if (player) {
      player.destroy();
    }
    player = new window.YT.Player(playerIFrameID, {
      videoId,
      events: {
        onReady: ({ target, data }) => {
          this.addEvent('player ready');
          if (params.embedConfig?.enableIma) {
            if (typeof window.YT.ImaManager !== 'undefined'){
              new window.YT.ImaManager(
                target,
                playerIFrameID,
                adDivID,
                makeAdsRequest
              );
            } else {
              console.warn(
                "YT.ImaManager is undefined. If you are running the app locally, " +
                "make sure to proxy to youtube-pfp-player-demo.local.dev-gutools.co.uk (See README.md)" +
                "This domain is allowlisted to fetch the IMA version of the iframe api script."
              )
            }
          }
        },
        onStateChange: ({ target, data }) => {
          Object.entries(window.YT.PlayerState).forEach(([state, value]) => {
            if (value === data) {
              this.addEvent(`video ${state.toLowerCase()}`);
            }
          });
        },
        onError: ({ target, data }) =>
          this.addEvent(`an error occurred within the player ${data}`),
        onAdStart: ({ target, data }) => {
          this.addEvent(
            `${target.getCurrentTime() === 0 ? 'pre-roll' : ''} advert started`
          );
        },
        onAdEnd: ({ target, data }) => this.addEvent('advert ended'),
        onAdSkip: ({ target, data }) => this.addEvent('advert skipped'),
      },
      ...params,
    });
  };

  componentDidUpdate = prevProps => {
    const { params, videoId } = this.props;

    if (params !== prevProps.params) {
      youTubeIframeAPIReady().then(() => {
        this.initializeYouTubePlayer(videoId, params);
      });
    }
  };

  componentDidMount = () => {
    const { params, videoId } = this.props;

    youTubeIframeAPIReady().then(() => {
      this.initializeYouTubePlayer(videoId, params);
    });
  };

  componentWillUnmount() {
    player.destroy();
  }

  renderEvents() {
    const { events } = this.state;

    return (
      <Events>
        {events.map((event, index) => {
          return (
            <li key={index}>
              <Event>{event}</Event>
            </li>
          );
        })}
      </Events>
    );
  }

  render() {
    const { showEvents } = this.props;
    return (
      <Player>
        <SixteenByNineContainer>
          <div
            id={playerIFrameID}
            title="YouTube player"
            allowFullScreen
            ref={this.setPlayerRef}
          ></div>
          <div id={adDivID}></div>
        </SixteenByNineContainer>
        {showEvents && this.renderEvents()}
      </Player>
    );
  }
}
