import defaultParams, { BOOLEAN, standardPlayerParams, embedConfigParams, adsConfigParams, adTagParametersParams } from '../params';
import deepClone from './deepClone';

export function paramsFromLocation({ search }) {
  const qs = new URLSearchParams(search);
  const params = deepClone(defaultParams);

  qs.forEach((value, key) => {
    const { type } = params[key];
    params[key].value = type === BOOLEAN
      ? value === "true"
      : value;
  });

  return params;
}

export function paramsFromObject(obj) {
  const params = deepClone(defaultParams);
  Object.entries(obj).forEach(([key, value]) => params[key].value = value);
  return params;
}

export function paramsToUrlQueryString(params) {
  const nonDefaultParams = Object.keys(params).reduce((acc, param) => {
    const { defaultValue, value } = params[param];
    return typeof value !== 'undefined' && value !== defaultValue
      ? {...acc, [param]: value}
      : acc;
  }, {});

  return new URLSearchParams(nonDefaultParams);
}

export function shapeParams(params) {
  return Object.keys(params).reduce((acc, key) => {
    const { value, defaultValue, type } = params[key];
    const isDefaultValue = value === undefined || value === defaultValue || value === '';

    // value not changed from the default, don't set it to keep params object small
    if (isDefaultValue) {
      return acc;
    }

    const isStandardPlayerParam = Object.keys(standardPlayerParams).includes(key);
    const isEmbedConfigParam = Object.keys(embedConfigParams).includes(key);
    const isAdsConfigParam = Object.keys(adsConfigParams).includes(key);
    const isAdTagParametersParam = Object.keys(adTagParametersParams).includes(key);

    if (isStandardPlayerParam) {
      if (!acc.playerVars) {
        acc.playerVars = {};
      }

      switch(type) {
        case BOOLEAN: {
          // `iv_load_policy` is `1` or `3`
          // https://developers.google.com/youtube/player_parameters#iv_load_policy
          if (key === 'iv_load_policy') {
            return { ...acc, playerVars: { ...acc.playerVars, [key]: value ? 3 : 1 }};
          }

          return { ...acc, playerVars: { ...acc.playerVars, [key]: value ? 1 : 0 }};
        }
        default: {
          return { ...acc, playerVars: { ...acc.playerVars, [key]: value }};
        }
      }
    }

    if (isEmbedConfigParam) {
      if (!acc.embedConfig) {
        acc.embedConfig = {};
      }

      return {
        ...acc,
        embedConfig: { ...acc.embedConfig, [key]: value }
      };
    }

    if (isAdsConfigParam) {
      if (!acc.embedConfig) {
        acc.embedConfig = { adsConfig: {} };
      }

      return {
        ...acc,
        embedConfig: {
          ...acc.embedConfig,
          adsConfig: {
            ...acc.embedConfig.adsConfig,
            [key]: value
          }
        }
      }
    }

    if (isAdTagParametersParam) {
      if (!acc.embedConfig) {
        acc.embedConfig = { adsConfig: {} };
      }
      if (!acc.embedConfig.adsConfig) {
        acc.embedConfig.adsConfig = { adTagParameters: {} };
      }

      return {
        ...acc,
        embedConfig: {
          ...acc.embedConfig,
          adsConfig: {
            ...acc.embedConfig.adsConfig,
            adTagParameters: {
              ...acc.embedConfig.adsConfig.adTagParameters,
              [key]: value
            }
          }
        }
      }
    }

    return acc;
  }, {});
}